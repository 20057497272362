/* ----------------------------------------------------------------------------
 * Structure
 * ------------------------------------------------------------------------- */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');


/* global box-sizing */
*,
*:after,
*:before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

/* html element 62.5% font-size for REM use i.e. 1rem = 10px*/
html {
    font-size: 62.5%;
    height:100%;
    min-height:100%;
}

body {
    font: 400 16px/1.4 'Roboto', sans-serif;
    color: $font-color-default;
    margin: 0;
    background: $w !important;
    height:100%;
    min-height:100%;
}

/* clearfix */
.clearfix:before,
.clearfix:after {
    content: ' ';
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.hidden {
    display:none !important;
}

img {
    max-width: 100%;
    vertical-align: bottom;
    height: auto;
}

a {
    color: #444;
    text-decoration: none;
}

a:hover {
    color: #444;
}

a:focus {
    outline: 0;
}

a:hover,
a:active {
    outline: 0;
}

input:focus {
    outline: 0;
    border: 1px solid $c2;
}

ol, ul {

}
select{
    width: 100%;
}

figure {
    margin:0;
}