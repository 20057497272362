/**
 * Base SCSS
 */

@media screen and (min-width: 992px) {
	.device-only {display:none;}
	.desktop-only {display:initial;}
}

@media screen and (max-width: 992px) {
	.device-only {display:initial;}
	.desktop-only {display:none;}
}

body {
	@media screen and (min-width: 992px) {
		margin-top:100px;
	}
	@media screen and (max-width: 992px) {
		margin-top: 50px;
	}
}

.container {

	max-width: $container-width;
	width:100%;
	margin:0 auto;
	padding-top:0;
	padding-bottom:0;

	@media screen and (max-width: 1240px) {
		padding:0 20px;
	}
}


#header {
	text-align: center;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $background-color--header--primary;

	body.logged-in & {
		top:32px;
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (min-width: 992px) {
			height: 100px;
		}

		@media screen and (max-width: 992px) {
			height: 50px;
		}

		figure {
			align-self: flex-end;
			order:1;
			margin:0 0 10px 0;


			@media screen and (min-width: 992px) {
				width: 200px;
				height: 60.2px;
				margin-bottom:15px;
			}
			@media screen and (max-width: 992px) {
				width: 100px;
				height: 30.09px;
			}

			svg {

				@media screen and (min-width: 992px) {
					width: 200px;
					height: auto;
				}

				@media screen and (max-width: 992px) {
					width: 100px;
					height:auto;
				}

				margin:0;
			}
		}

		nav {
			align-self: flex-end;
			margin-bottom:10px;

			@media screen and (max-width: 992px) {
				order:3;

				ul.first-level {

					display:none;

					position: absolute;
					left:0;
					top:50px;
					background: rgba(235,198,220,0.95);
					padding:0;
					margin:0;
					width: 100%;
					padding:2em 0;
					text-transform: uppercase;
					line-height:3;

					&.open {
						display:block;
					}

					a {
						color:rgb(30,30,30);
						font-weight:500;

						&:hover {
							color:rgb(90,90,90);
						}
					}
				}

				button.toggle-device-navigation {
					display:block;
					border:1px solid rgb(90,90,90);
					background-color:#fff;
					color: rgb(90,90,90);
					border-radius:0;

					&:hover {
						border-color:#f47b69;
						color:#f47b69;
					}
				}
			}

			@media screen and (min-width: 992px) {
				flex:1;
				order:2;

				button.toggle-device-navigation {
					display:none;
				}

				ul.first-level {

					width: 100%;
					margin: 0 auto;
					list-style: none;
					line-height: 1;
					overflow: hidden;

					li.first-level-menu-item {
						display: inline-block;
						font-size: 15px;

						> a {
							padding: 10px 12px;
							display: inline-block;
							font-weight: normal;
							text-transform: uppercase;
							color: $font-color-default;
						}
					}
				}
			}
		}

		div.actions {

			font-size: 15px;

			@media screen and (max-width: 992px) {
				order:2;
				flex:1;
				line-height:50%;
			}

			@media screen and (min-width: 992px) {
				order:3;
				width:100px;
				margin-bottom:10px;
				align-self: flex-end;
			}

			a {
				padding:10px;
				display:inline-block;

				@media screen and (min-width: 992px) {

					padding:10px 3px;

					&:last-child {
						margin-left: 20px;
					}
				}
			}

/*
			@media screen and (min-width: 992px) {
				width: 250px;
			}
			@media screen and (max-width: 992px) {
				width: 65px;
			}
*/



			button {
				background: transparent;
				border: 0;

				&.open-search {
					svg {
						width: 26px;
						height: 26px;

						path {
							fill: #db677b;
						}
					}

					@media screen and (max-width: 992px) {
						display: none;
					}
				}

				&.open-mobile-navigation {

					display: none;
					margin-top: 4px;

					svg {
						width: 29px;
						height: 13.5px;

						path {
							margin: 0;
							padding: 0;
							fill: #fff;

							body.inverted & {
								fill: #000;
							}
						}
					}

					@media screen and (max-width: 992px) {

						display: block;

						body.navigation-opened & {
							display: none;
						}
					}
				}

				&.close-mobile-navigation {

					display: none;
					margin-top: 4px;

					svg {
						width: 22px;
						height: 22px;

						path {
							fill: #fff;

							body.inverted & {
								fill: #000;
							}
						}
					}

					@media screen and (max-width: 992px) {
						display: none;

						body.navigation-opened & {
							display: block;
						}
					}
				}
			}
		}
	}
}

#content {
	margin: 40px auto;
}

#event-carousel {

	max-width:1440px;
	margin:0 auto;




	.slides {
		width: 100%;
		padding-bottom:40%;
		position:relative;

		.event-carousel-pager {
			z-index:500;
			position: absolute;
			bottom:0;
			left:0;
			width:100%;
			text-align:center;

			span {
				height:15px;
				width:15px;
				background-color:#fff;
				opacity:.5;
				border-radius:50%;
				display:inline-block;
				margin:0px 5px 20px 5px;
				color:transparent;
				cursor:pointer;

				&.cycle-pager-active {
					opacity:1;
				}
			}
		}

		.slide {
			position: absolute;
			width:100%;
			height:100%;
			background-size: cover;

			.container {
				height:100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;

				.details {


					max-width:500px;
					margin-bottom:15%;
					text-shadow:1px 1px 4px rgba(0,0,0,0.5);

					h2 {
						margin:0 0 5px 0;
						font-size:30px;
						color:#fff;
						line-height:1;
						font-weight:200;

						a {
							color:#fff;
						}
					}

					p {
						margin:0 0 10px 0;
						font-size:16px;
						color:#fff;
					}

					a.button {
						font-size:18px;
						display:inline-block;
						border:3px solid #fff;
						color:#fff;
						padding:5px 20px;
						text-transform: uppercase;
					}

					&.inverted {
						h2 a, p, a.button {
							color:#000;
							border-color:#000;
						}
					}

					@media (min-width: 678px) {
						margin-bottom:5%;

						h2 {
							font-size:40px;
						}

						p {
							font-size:20px;
						}

						a.button {
							font-size: 25px;
						}
					}
				}
			}
		}
	}
}

#program {

	.filter {
		@include clearfix();
		background-color: $background-color--footer;
		margin:0 0 20px 0;

		ul {
			list-style: none;
			padding:0;
			margin:0;

			li {
				float:left;

				a {
					display:block;
					color:#fff;
					padding:10px 20px;
					text-transform: uppercase;

					&:hover {
						background-color: rgba(255,255,255,0.1);
					}

					&.selected {
						background-color:#7aced5;
					}
				}
			}
		}
	}

	.grid {
		@include clearfix();

		margin:0 -1.6666666667%;

		.event {

			padding:0;
			text-align:center;
			text-transform: uppercase;
			margin:0 1.6666666667% 75px;
			width:96.6666666666%;



			@media (min-width: 678px) {
				float:left;
				margin-bottom:50px;

				@media (max-width: 992px) {
					width: 46.6666666666%;

					&.clear-left-pad {
						clear: left;
					}
				}
			}

			@media (min-width: 992px) {

				width:30%;

				&.clear-left-desktop {
					clear: left;
				}
			}

			figure.image {
				background-color:#8a8a8a;
				height:0;
				width:100%;
				padding-bottom:66%;
				cursor:pointer;
				position: relative;
				background-size:cover;

				.overlay {

					@include clearfix();

					z-index:2;
					position: absolute;
					top:0;
					left:0;
					width: 100%;
					height:100%;
					background-color:rgba(244,124,105,0.75);
					display:none;

					a {
						display:inline-block;
						float:left;
						width:50%;
						height:100%;
						cursor:pointer;
						text-align: center;

						&:first-child {
							padding:50px 10px 50px 50px;
						}

						&:last-child {
							padding:50px 50px 50px 10px;
						}


						figure {

							margin:0;

							svg {

								path {
									fill:rgba(255,255,255,1);
								}
							}
						}

						span {
							font-weight: 600;
							color:rgba(255,255,255,0.5);
						}

						&:hover {
							figure {
								svg {
									path{
										fill:rgba(255,255,255,.8);
									}
								}
							}

							span {
								color:rgba(255,255,255,1);
							}
						}
					}
				}

				.sale-status {

					position: absolute;
					left:0;
					top:0;
					z-index:1;

					img {
						width:140px;
					}
				}

				&:hover {
					.overlay {
						display:block;
					}
				}
			}

			h5 {
				font-weight:200;
				margin:20px 0 0 0;
			}

			h3 {
				margin:0;
			}
		}
	}

}

#search {
	position:fixed;
	z-index:980;
	top:0;
	right:0;
	bottom:0;	
	left:0;
	width: 100%;
	height: 100%;
	background-color: rgba(244,123,105,0.95);
	transform: translate(0%, 0%);		
	overflow-y: scroll;
    overflow-x: none;
	-webkit-overflow-scrolling: touch;

	display:flex;	
  	justify-content: center;

	.content-wrapper {
		max-width: 660px;
		width:100%;
		margin:50px 20px 0 20px;

		form {
			width:100%;
			margin: 75px auto 0 auto;

			@media (min-width: 678px) {
				margin: 100px auto 0 auto;
			}

			@media (min-width: 992px) {
				margin: 200px auto 0 auto;
			}


			input {
				border:0;
				width:100%;
				height: 45px;	
				background-color:#fff;
				color:#000;
				font-size: 20px;
				text-indent:20px;
				position:relative;

				&:focus {
					border:0;
				}
			}
		}

		.search-result {
			margin-top:65px;			

			.item {

				h2 {
					line-height: 1;
					font-size:16px;
					padding:10px 0;
					border-bottom:1px solid #FFFFFF;
					text-transform: uppercase;

					a {
						color: #FFFFFF;
					}
				}

				p {
					font-size: 14px;
					padding:10px 0;
					color:#fafafa;
					margin:0;

					body.inverted & {
						color:#333;
					}

					&.dates {

						@include clearfix();

						font-size:13px;
						padding:0;
						color:#fff;

						a.button {
							float:right;
							color:#fff;
							background-color: #2277bc;
							font-size:inherit;
							white-space:nowrap;
							padding:2px 5px;
							display:inline-block;
							margin-top:-2px;
							font-weight:bold;
						}
					}
				}

				&:last-child {
					border-bottom:0;
				}
			}
		}
	}
}

body.event {

	.event-image {
		width:100%;
		height:0;
		padding-bottom:66%;
		background-size:cover;

		@media (min-width: 992px) {
			padding-bottom:40%;
		}
	}

	.event-details {

		background-color: #f7f7f7;
		border-bottom:1px solid #e7e7e7;
		margin:-40px -20px 0 -20px;
		padding:20px;

		@media (min-width: 678px) {
			background-color:transparent;
			border:0;
			margin:initial;
			padding:initial;

			table {
				width:100%;

				.prices {

					td {
						text-align:left !important;
					}
				}
			}
		}

		td {
			text-transform: uppercase;
			display:block;
			line-height:1.5;
			vertical-align: top !important;

			@media (min-width: 678px) {
				display:table-cell;

				&:last-child {
					text-align: right;
				}
			}

			a {
				border:1px solid #333;
				display:inline-block;
				padding:2px 10px;

				&:hover {
					border-color:#666;
					color:#666;
				}
			}
		}
	}

	h1 {
		text-transform: uppercase;
		font-size: 30px;
		font-weight: 200;
		line-height: 1.3;

		@media (min-width: 678px) {
			font-size: 40px;
		}

		@media (min-width: 992px) {
			font-size: 45px;
		}

		span.sale-status {
			&.few-tickets {
				background-color: #7aced5;
			}
			&.sold-out {
				background-color: #f47c69;
			}

			font-weight:bold;
			display: inline-block;
			font-size:15px;
			padding: 10px 20px;
			text-transform: uppercase;
			margin: 0;
			color: #fff;
		}
	}

	.body {
		margin-bottom:150px;
	}
}

body.page {
	h1 {
		padding-top:20px;
		text-transform: uppercase;
		font-size: 25px;
		font-weight:200;
		line-height: 1.3;

		@media (min-width: 678px) {
			font-size: 35px;
		}

		@media (min-width: 992px) {
			font-size: 40px;
		}
	}

	h2 {
		padding-top:20px;
		text-transform: uppercase;
		font-size: 21.333px;
		font-weight:200;
		line-height: 1.3;
	}
}


#program {
	
	//display:flex;

	.section-content-container {

		margin: 50px auto 0 auto;

		hr {
			border-color:#fafafa;

			&.fat {				
				border-color:#000;
			}
		}

		.row {
			margin:0;
			@include clearfix;

			@media screen and (min-width: 992px) {

				&.headings {
					border-bottom: 1px solid #000;
					margin-bottom: 25px;
					padding-top: 80px;

					.column {
						font-size: 21px;
						font-weight: normal;
						vertical-align: baseline;
						position: relative;

						&.with-filter {

							cursor:pointer;

							&.selected {
								color: #db677a;
							}

							span:after {
								border-style: solid;
								border-width: 0.10em 0.10em 0 0;
								content: '';
								display: inline-block;
								position: relative;
								height: 0.45em;
								top: 0.35em;
								left: 0.25em;
								transform: rotate(135deg);
								vertical-align: top;
								width: 0.45em;
							}

							.filter {
								display:none;
							}

							&:hover {
								color: #666;
								cursor: pointer;

								.filter {
									display: block !important;
									position: absolute;
									background-color: #fff;
									z-index: 10;
									line-height: 1.75;
									margin: 0 0 0 -20px;
									padding: 0;
									list-style: none;
									display: block;
									border: 1px solid #444;

									li {
										display: block;
										cursor: pointer;
										padding: 0 20px;

										&:hover {
											background-color: #f7f7f7;
										}

										&.selected {
											background-color: #db677b;
											color: #fff !important;
										}
									}
								}
							}
						}

						span {
							position: absolute;
							bottom: 0;
						}

						&.year-and-month {
							//font-size: 26px;
							font-weight:bold;
							padding-left: 5%;
						}

						&.action {
							a {
								display: none;
							}
						}
					}
				}

				&.event {
					padding: 25px 0;
					border-bottom: 1px solid #9e9e9e;

					&.last {
						border:0;
					}
				}

				.column {

					float: left;

					&.year-and-month {
						width: 20%;
						padding-left: 5%;

						a {
							img {
								max-width: 240px;
								width: 100%;
								height: auto;
							}

							&.sales-status {
								position:relative;
								overflow:hidden;
								display:inline-block;

								&:after {
									position:absolute;
									top:50%;
									text-indent:25%;
									left:-30%;
									z-index:1;
									width:140%;
									text-align:center;
									transform:rotate(-30deg);

									color:#fff;
									font-size:22px;
									font-weight:bold;
								}

								&.sales-status-1:after {
									content:"FÅ BILLETTER";
									background-color:#f69576;
								}

								&.sales-status-2:after {
									content:"UTSOLGT";
									background-color:#4b6375;
								}

								&.sales-status-3:after {
									content:"AVLYST";
									background-color:#db677a;
								}
							}
						}
					}

					&.place {
						width: 30%;
						padding-left: 30px;
                        padding-right: 30px;

						h3 {
							font-size: 26px;
							font-weight: normal;
							margin: 0;
							line-height: 1.1;
						}
					}

					&.categories {
						width: 20%;
					}
					&.price {
						width: 10%;
					}
					&.action {
						text-align: right;
						padding-right: 5%;
						width: 19%;

						a.button {
							border: 2px solid #db677a;
							font-size: 28px;
							white-space: nowrap;
							padding: 10px 20px;
							display: inline-block;
							//@include vertical-align();
						}
					}

					p {
						margin: 0;
					}
				}
			}


			@media screen and (max-width: 992px) {

				&.headings {
					margin-top: 25px;

					.column {

						&.with-filter {

							&.selected {
								color: #db677a;
							}

							span:after {
								border-style: solid;
								border-width: 0.10em 0.10em 0 0;
								content: '';
								display: inline-block;
								position: relative;
								height: 0.45em;
								top: 0.65em;
								left: 0.35em;
								transform: rotate(135deg);
								vertical-align: top;
								width: 0.45em;
							}

							&.open {
								color: #666;
								cursor: pointer;

								.filter {
									display: block !important;
									position: absolute;
									background-color: #fff;
									z-index: 10;
									line-height: 1.75;
									margin: 0 0 0 -20px;
									padding: 0;
									list-style: none;
									display: block;
									border: 1px solid #444;

									li {
										display: block;
										cursor: pointer;
										padding: 0 20px;

										&:hover {
											background-color: #f7f7f7;
										}

										&.selected {
											background-color: #db677b;
											color: #fff !important;
										}
									}
								}
							}
						}

						&.year-and-month {
							width: 70%;
							font-size: 16px;
							font-weight:600;
							line-height: 2;
							padding-left:5%;
						}

						&.place, &.categories, &.price {
							display: none;
						}

						&.action {
							width: 29%;
							text-align: right;
							padding-right: 5%;

							a {
								display: inline-block;
								margin-left: 10px;

								svg {
									width: 18.45px;
									height: 13.653px;

									path {
										fill: #686868;
									}
								}

								&:hover, &.selected {
									svg {
										path {
											fill: #000;
										}
									}
								}
							}
						}
					}
				}


				body.list & {

					&.event {
						padding: 15px 0;
						border-top: 1px solid #9e9e9e;

						.column {

							&.year-and-month, &.price {
								display: none;
							}

							&.place {
								width: 40%;
								padding-left: 5%;
								padding-right: 10px;
								overflow: hidden;

								h3 {
									margin: 0;
									font-weight: 400;
									font-size: 14px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}

								.place {
									font-size: 10px;
									margin: 0;
									padding: 0;
								}

								a.read-more {
									display: none;
								}
							}

							&.categories {
								width: 30%;
								font-size: 10px;
								padding-left: 10px;

								.categories {
									display: none;
								}
							}

							&.action {
								width: 28%;
								text-align: right;
								padding-right: 5%;

								a.button {
									font-size: 10px;
									display: inline-block;
									max-width: 70px;
									border: 1px solid #db677a;
									padding: 2px 5px;
								}
							}

							p {
								margin:0;
							}
						}						
					}

				}

				body.with-thumbnails & {

					&.event {

						position: relative;
						margin-bottom:10px;

						.column {

							&.year-and-month {

								width: 100%;

								a {
									pointer-events: none;
									cursor: default;

									img {
										width:100% !important;
										height:auto;
									}

									&.sales-status {
										position:relative;
										overflow:hidden;
										display:inline-block;

										&:after {
											position:absolute;
											bottom:0;
											left:0%;
											z-index:0;
											width:100%;
											text-align:left;
											color:#fff;
											font-size:16px;
											padding:10px 10px 30px 10px;
											font-weight:bold;
										}

										&.sales-status-1:after {
											content:"FÅ BILLETTER";
											background-color:#f69576;
										}

										&.sales-status-2:after {
											content:"UTSOLGT";
											background-color:#4b6375;
										}

										&.sales-status-3:after {
											content:"AVLYST";
											background-color:#db677a;
										}
									}
								}
							}

							&.place, &.categories, &.price, &.action {
								position: absolute;
							}

							&.place {
								top:10px;
								left:10px;

								h3 {
									margin:0;
									font-size:14px;

									a {
										color: #fff;
									}
								}

								p {display:none;}
							}


							&.categories {
								bottom:10px;
								left:10px;

								p {
									font-size: 10px;
									color: #fff;
									padding:0;
									margin:0;

									br, br:after {
										content: ' ';
									}

									.categories {
										display: none;
									}
								}
							}

							&.price {
								bottom:10px;
								right:10px;

								p {
									font-size:10px;
									color:#fff;
									padding:0;
									margin:0;
								}
							}

							&.action {
								top:10px;
								right:10px;

								p {
									margin:0;
									padding:0;

									a.button {
										color: #000;
										font-size: 10px;
										background-color: #fff;
										border: 1px solid #db677a;
										padding: 5px 10px;
									}
								}
							}
						}

						&.inverted {						
							* {
								color:#000 !important;
							}
						}
					}
				}
			}
		}
	}
}

#social-medias {

	.container {

		@include clearfix();

		.element {

			margin-bottom:40px;

			@media screen and (min-width: 768px) {
				width: 48.5%;

				float:left;

				&:last-child {
					margin-left:3%;
				}
			}


			h2 {
				background: $background-color--footer;
				margin:0;
				padding:10px 20px;
				line-height:1;
				color:#fff;
				font-weight:200;
				font-size:16px;
				text-transform: uppercase;
			}

			.content {
				width:100%;
				margin:20px auto 0 auto;
				text-align:center;
				height:500px;
			}

			&.facebook {

				.fb-page {
					max-width:100%;

					span {
						max-width: 100%;

						iframe {
							max-width: 100%;
						}
					}
				}
			}

		}
	}
}

#place {

	@media screen and (min-width: 992px) {

		@include clearfix();

		margin:140px auto 100px auto;
		max-width: 1080px;

		@media screen and (max-width: 1120px) {
			margin-right:20px;
			margin-left:20px;
		}

		.place-details {
			float:left;
			width:250px;
			padding-right: 20px;
		}

		.place-content {
			float:left;
			width: calc(100% - 250px);

			.social-media-buttons {
				float:right;
				margin-left:20px;
			}

			h1 {
				margin-top:0;
			}
		}
	}

	@media screen and (max-width: 992px) {

		margin-top:70px;

		.place-details {
			padding:20px;
			margin:0 0 20px 0;
			border:1px solid #e4e4e4;
			border-width:1px 0;
			background:#fafafa;
			font-size:80%;

			.social-media-buttons {
				display:none;
			}
		}

		.place-content {
			margin: 0 20px 20px 20px;

			h1 {
				font-size:24px;
				float:left;
			}

			font-size:85%;
		}
	}


	.social-media-buttons {
		a {
			display: inline-block;

			svg {
				width: 44px;
				height: 44px;

				path {
					transition: all .1s ease-in;
					fill: #686969;
				}
			}

			&:hover {
				svg {
					path {
						fill: #db677a;
					}
				}
			}
		}
	}
}

#event {

	@media screen and (min-width: 992px) {

		margin: 140px 0 100px 0;
		display: flex;

        .device-only {display:none;}
        .desktop-only {display:initial;}

		.previous-event, .next-event {
			flex: 1;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;

			a {
				width: 125px;
				display: inline-block;
				text-align: center;

				svg {
					width: 31px;
					height: 26px;
				}
			}
		}

		.next-event {
			justify-content: flex-end;
		}

		.current-event {
			flex-basis: auto;
			max-width: 1080px;
			width:100%;
			display: flex;

			@media screen and (max-width: 1024px) {
				max-width: 800px;
			}

			.event-details {
				flex: 0 0 250px;
				padding-right: 10px;

                .event-images {
                  display:none;
                }

				a.button {
					display: inline-block;
					border: 2px solid #db677a;
					font-size: 23px;
					white-space: nowrap;
					padding: 5px 20px;
				}

				p {
					margin-top: 20px;
					font-size: 14px;
				}

				.event-details-content-wrapper {
					max-width: 100%;
					background-color: rgba(255, 255, 255, 0.4);

					.social-media-buttons {
						margin-top: 10px;
						opacity: 0;
						transition: all .1s ease-in;

						a {
							margin-right: 5px;
						}
					}
				}

				&.sticky {

					.event-details-content-wrapper {
						position: fixed;
						top: 84px;

						.social-media-buttons {
							opacity: 1;
						}
					}
				}
			}

			.event-content {

				h1 {
					font-size: 30px;
					margin-top: 0;
					line-height: 1.3;
				}

				.social-media-buttons {
					float: right;

					a {
						display: inline-block;
						margin-left: 5px;
					}
				}
			}

			.event-images {
				margin: 1em 0;
			}
		}
	}

	@media screen and (max-width: 992px) {

        padding-top:46px;
        padding-bottom:92px;

		.device-only {display:initial;}
        .desktop-only {display:none;}

        .event-details {
          position: relative;

          .event-images {}

          .event-details-content-wrapper {
            background:rgba(0,0,0,0.5);
            position: absolute;
            width:100%;
            max-width:100%;
            bottom:0;
            left:0;
            padding:10px;

            p {
              font-size:10px;
              color:#fff;
              margin:0;

              .place, .venue, .shows, .categories {
                display: none;
              }
              .date {
                float: left;
              }
				.time {margin-left: 5px;}
            }


            .button {
              position:absolute;
              top:10px;
              right:10px;
              font-size:12px;
              display:inline-block;
              min-width:88px;
			  max-width: 100%;
              border:1px solid #db677a;
              background-color:#fff;
              text-align:center;
              padding:5px 5px;
            }

            .social-media-buttons {
              display:none;
            }

          }
        }

        .event-content {

          padding:0 20px;
          font-size:14px;

          h1 {
            font-size: 18px;
          }

          /*h2 {
            display:none;
          }*/

          .social-media-buttons {
            float:right;
          }

        }

        .previous-event, .next-event {
          position:fixed;
          width:50%;
          bottom:0;
          padding:10px 20px 5px 20px;
          background:rgba(255,255,255,0.9);

          &.previous-event {
            left:0;
            text-align:left;
          }

          &.next-event {
            right:0;
            text-align:right;
          }

          svg {
            width: 35px;
          }
        }
	}

    .social-media-buttons {
      a {
        display: inline-block;

        svg {
          width: 44px;
          height: 44px;

          path {
            transition: all .1s ease-in;
            fill: #686969;
          }
        }

        &:hover {
          svg {
            path {
              fill: #db677a;
            }
          }
        }
      }
    }


	#shows {


		h2 {
			padding-top: 75px;
			margin-left:10px;
		}

		.event {
			margin: 0;
			@include clearfix;

			border-top: 1px solid #1a1a1a;
			padding:20px 0;

			.column {

				float:left;

				p {
					margin:0;
				}

				&.title {
					width: 31%;
					padding-left:10px;

					h3 {
						margin:0;
					}

					@media screen and (max-width: 992px) {
						width:30%;
					}
				}

				&.date {
					width: 26%;
					@media screen and (max-width: 992px) {
						width:30%;
						text-align: right;
					}
				}

				&.price {
					width: 21%;
				}

				&.action {
					width: 21%;
					text-align:right;

					@media screen and (max-width: 992px) {
						width:30%;
					}

					.button {
						font-size:18px;
						display:inline-block;
						line-height:42px;
						/*min-width:158px;*/
						max-width: 100%;
						border:2px solid #db677a;
						background-color:#fff;
						text-align:center;
						padding:0 5px;

						@media screen and (max-width: 992px) {
							font-size:14px;
							line-height:35px;
						}
					}
				}
			}
		}
	}
}


#coming-events, #similar-events {

    @media screen and (max-width: 992px) {
      display:none;
    }

	margin:100px 0 0 0;

	.content-wrapper {
		max-width:1080px;

        @media screen and (min-width: 1080px) {
          margin:0 auto;
        }

        @media screen and (max-width: 1080px) {
          margin:0 20px;
        }

		h2 {
			max-width:1080px;
			margin:0 auto;

            @media screen and (min-width: 992px) {
              font-size: 28px;
              line-height: 2;
            }

            @media screen and (max-width: 992px) {
              font-size:20px;
              line-height: 1.5;
            }
		}
	}
	
	hr {
		margin:0;
		border-top:1px solid #1a1a1a;
	}

	.event-list {

		@include clearfix;
		padding:30px 0;

		.event {
			display: inline-block;

          @media screen and (min-width: 992px) {
            float:left;
            width:32%;
          }

          @media screen and (max-width: 992px) {
            width:100%;
          }



			figure {
				width: 100%;
				height:0;
				padding-bottom:51.27388535%;
				background-repeat: no-repeat;
				background-size: cover;
			}

			h3 {
				font-weight:normal;
				font-size:20px;
				margin:5px 0 0 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			p {
				margin:0 0 5px 0;
				font-size: 15px;
			}

			a.button {
				font-size:27px;
				display:inline-block;
				min-width:196px;
				line-height:48px;
				border:2px solid #db677a;
				background-color:#fff;
				text-align:center;
				padding:0 5px;
			}


			&:nth-child(2){
				margin-left:2%;
				margin-right:2%;
			}
		}
		
		.spinner {
			width: 40px;
			height: 40px;
			background-color: #333;
			margin: 100px auto;		
			animation: sk-rotateplane 1.2s infinite ease-in-out;
			outline:1px solid red;
				
			@keyframes sk-rotateplane {
				0% { transform: perspective(120px) rotateX(0deg) rotateY(0deg); } 
				50% { transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); } 
				100% { transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); }
			}
		}
	}
}



.loader {
	background-color:rgba(255,255,255,0.6);
	width: 100%;
	height:100%;
	position: fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index: 10;
	
	.blob {
		filter: url('#gooey');
		width: 2rem;
		height: 2rem;
		background: #db677a;
		border-radius: 50%;
		position: absolute;
		left: calc(50% - 1rem);
		top: calc(50% - 1rem);
		box-shadow: 0 0 1rem rgba(255, 255, 255, 0.15);

		&.blob-2 {
			animation: animate-to-2 1.5s infinite;
		}

		&.blob-3 {
			animation: animate-to-3 1.5s infinite;
		}

		&.blob-1 {
			animation: animate-to-1 1.5s infinite;
		}

		&.blob-4 {
			animation: animate-to-4 1.5s infinite;
		}

		&.blob-0 {
			animation: animate-to-0 1.5s infinite;
		}

		&.blob-5 {
			animation: animate-to-5 1.5s infinite;
		}
	}	
}

@keyframes animate-to-2 {
    25%,
    75% {
        transform: translateX(-1.5rem) scale(0.75);
    }
    95% {
        transform: translateX(0rem) scale(1);
    }
}

@keyframes animate-to-3 {
    25%,
    75% {
        transform: translateX(1.5rem) scale(0.75);
    }
    95% {
        transform: translateX(0rem) scale(1);
    }
}

@keyframes animate-to-1 {
    25% {
        transform: translateX(-1.5rem) scale(0.75);
    }
    50%,
    75% {
        transform: translateX(-4.5rem) scale(0.6)
    }
    95% {
        transform: translateX(0rem) scale(1);
    }
}

@keyframes animate-to-4 {
    25% {
        transform: translateX(1.5rem) scale(0.75);
    }
    50%,
    75% {
        transform: translateX(4.5rem) scale(0.6)
    }
    95% {
        transform: translateX(0rem) scale(1);
    }
}

@keyframes animate-to-0 {
    25% {
        transform: translateX(-1.5rem) scale(0.75);
    }
    50% {
        transform: translateX(-4.5rem) scale(0.6)
    }
    75% {
        transform: translateX(-7.5rem) scale(0.5)
    }
    95% {
        transform: translateX(0rem) scale(1);
    }
}

@keyframes animate-to-5 {
    25% {
        transform: translateX(1.5rem) scale(0.75);
    }
    50% {
        transform: translateX(4.5rem) scale(0.6)
    }
    75% {
        transform: translateX(7.5rem) scale(0.5)
    }
    95% {
        transform: translateX(0rem) scale(1);
    }
}



#footer {
	margin:0 auto;
	background-color: $background-color--footer;
	padding:50px 0;

	.container {

		figure {

			margin:0 0 40px 0;
			width:125px;

			svg {
				width: 100%;
				fill: #fff;

				path {
					fill: #fff;
				}
			}

			&.randaberg-kommune {
				@media screen and (min-width: 678px) {
					float:right;
				}
			}
		}

		color:#fff;

		address {
			font-style:normal;

		}

		.producer {
			svg {
				width: 75px;
				height:28.52px;
				margin:0 0 -7px 5px;

				path, rect{
					fill: #fff;

					&.circle {
						fill:#f99000;
					}
				}
			}
		}

		a {
			color:#fff;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}