/**!
Theme Name: Varen - Randaberg kulturscene
Description: The Wordpress theme for Randaberg Kulturscene
Version: 1.0
Author: Robert Ahlin Toftegaard, AMBIO Strong AS
Author URI: https://www.strong.no
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: gulp, boilerpate
Text Domain: RANDABERG
*/

/* ----------------------------------------------------------------------------
 * Mixins & Variables
 * ------------------------------------------------------------------------- */
@import "partials/mixins";
@import "partials/variables";

/* ----------------------------------------------------------------------------
 * Normalize
 * ------------------------------------------------------------------------- */

@import "vendors/normalize";

/* ----------------------------------------------------------------------------
 * Structure
 * ------------------------------------------------------------------------- */
@import "partials/structure";

/* WPCore */
@import "partials/wpcore";

/* Base */
@import "partials/base";