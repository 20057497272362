/* ----------------------------------------------------------------------------
 * WordPress Core Styles
 * ------------------------------------------------------------------------- */
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: darken($c1,10%);
    max-width: 100%;
    padding: 2rem;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: auto;
}

.wp-caption .wp-caption-text,
.gallery-caption {
    margin: 0;
    padding: 0.5rem;
}
.textwidget img{
    margin: 1rem 0;
}

.sticky {
}

.bypostauthor {
    background: inherit;
}

/* ----------------------------------------------------------------------------
 * Calendar widget
 * ------------------------------------------------------------------------- */
.widget_calendar {display: table;width: 100%;}
#wp-calendar {width: 100%; }
#wp-calendar caption { text-align: right; color: $b; font-size: 12px; margin-top: 10px; margin-bottom: 15px; }
#wp-calendar thead { font-size: 10px; }
#wp-calendar thead th { padding-bottom: 10px; }
#wp-calendar tbody { color: $b; font-family: sans-serif; font-weight: 300;}
#wp-calendar tbody td { background: $w; border: 1px solid $w; text-align: center; padding:8px;}
#wp-calendar tbody td:hover { background: $w; }
#wp-calendar tbody .pad { background: none; }
#wp-calendar tfoot #next { font-size: 10px; text-transform: uppercase; text-align: right; }
#wp-calendar tfoot #prev { font-size: 10px; text-transform: uppercase; padding-top: 10px; }

/* ----------------------------------------------------------------------------
 * Recommended sizes
 * ------------------------------------------------------------------------- */
 .size-auto,
 .size-full,
 .size-large,
 .size-medium,
 .size-thumbnail {
    max-width: 100%;
    height: auto;
 }

/* ----------------------------------------------------------------------------
 * Responsive
 * ------------------------------------------------------------------------- */
.wp-caption .wp-caption-text,
.gallery-caption{

     @include r(240)  { font: 300 1.4rem/1.8rem sans-serif; }
     @include r(320)  { font: 300 1.55rem/1.8 sans-serif;   }
     @include r(480)  { font: 300 1.8rem/1.8 sans-serif;    }
     @include r(768)  { font: 300 1.9rem/1.7 sans-serif;    }
     @include r(1024) { font: 300 2rem/3.1rem sans-serif;   }
     @include r(1280) { font: 300 2rem/3.1rem sans-serif;   }

 }