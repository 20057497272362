/* ----------------------------------------------------------------------------
 * Variables
 * ------------------------------------------------------------------------- */

$container-width : 1200px;

/* ----------------------------------------------------------------------------
 * Colors
 * ------------------------------------------------------------------------- */

$font-color-default : #292929;

$c1 		: #dedede;
$c2 		: #292929;

$w			: #ffffff;
$b			: #000000;
$gb			: #686868;
$g  		: #35ad68;
$y			: #FFFDD2;


/* ----------------------------------------------------------------------------
 * Primary Color Variants
 * ------------------------------------------------------------------------- */
 /*$color--primary: #406de4;*/
 /*$color--primary: #00BCD4;*/
 /*$color--primary: #03A9F4;*/
 /*$color--primary: #3498db;*/
 /*$color--primary: #2980b9;*/
 /*$color--primary: #3F51B5;*/
 /*$color--primary: #304FFE;*/
 /*$color--primary: #FF5722;*/
 /*$color--primary: #795548;*/
 /*$color--primary: #2ecc71;*/
 /*$color--primary: #27ae60;*/
 /*$color--primary: #35ad68;*/
 /*$color--primary: #1abc9c;*/
 /*$color--primary: #16a085;*/
 /*$color--primary: #9b59b6;*/
 /*$color--primary: #8e44ad;*/
 // $color--primary: #4A148C;
 /*$color--primary: #673AB7;*/
 /*$color--primary: #FFA000;*/
 /*$color--primary: #FBC02D;*/
 /*$color--primary: #f1c40f;*/
 /*$color--primary: #f39c12;*/
 /*$color--primary: #e67e22;*/
 /*$color--primary: #d35400;*/
 /*$color--primary: #e74c3c;*/
 /*$color--primary: #c0392b;*/
 /*$color--primary: #f44336;*/
 $color--primary: #ED4846;
 // $color--primary: #7f8c8d;
 /*$color--primary: #34495e;*/
 /*$color--primary: #607D8B;*/

/* Header background colors */
$background-color--header--primary: #ffffff;


$background-color--footer: #666666;